import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Teaser from "../components/teaser"

const GuidePage = () => (
	<Layout>
		<SEO title="Features"/>
		<div className="edge align-baseline trim site-padding-vertical fit-down">
			<h1>Features</h1>

			<div className="grid grid-columns section-margin-vertical ">
				<div className="span-12 medium-span-6 large-span-4 row-span-2"><Teaser to="/features/typography/" title="Typography" number="1" large/></div>
				<div className="medium-span-6 large-span-8"><Teaser to="/features/space/" number="2" title="Space"/></div>
				<div className="medium-span-3 large-span-4"><Teaser to="/features/grid/" number="3" title="Grid"/></div>
				<div className="span-6 medium-span-3 medium-row-span-2 large-span-2 large-row-span-1"><Teaser to="/features/flex/" number="4" title="Flex"/></div>
				<div className="span-6 medium-span-3 large-span-2 large-row-span-2"><Teaser to="/features/edge/" number="5" title="Edge"/></div>
				<div className="span-6 medium-span-6"><Teaser to="/features/viewports/" number="6" title="Viewports"/></div>
				<div className="span-6 medium-span-6 large-span-4"><Teaser to="/features/utilities/" number="7" title="Utilities"/></div>

			</div>
		</div>
	</Layout>
)

export default GuidePage
