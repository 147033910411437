import React from "react";
import {Link} from "gatsby"

import '../scss/6.components/teaser.scss';

export default class Teaser extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let className = "teaser trim fit-down";
		if(this.props.large){
			className = className+' teaser--first';
		}

		return (
			<Link to={this.props.to} className={className}>
				<span className="teaser__number">{this.props.number}</span>
				<span className="teaser__letter">{this.props.title.substring(0, 1)}</span>
				<div className="teaser__body">
					<h3 className="teaser__title">{this.props.title}</h3>
				</div>
				{this.props.children}
			</Link>
		)
	}
}
